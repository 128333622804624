body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* https://tailwindcss.com/docs/adding-custom-styles#using-arbitrary-values
The base layer is for things like reset rules or default styles applied to plain HTML elements.
The components layer is for class-based styles that you want to be able to override with utilities.
The utilities layer is for small, single-purpose classes that should always take precedence over any other styles. */

.rangesliderchart .plotPod {
  fill: #37789e;
  rx: 6px;
  ry: 6px;
}
.rangesliderchart .rangeLine {
  stroke: #37789e;
}
.rangesliderchart .rangeEnd {
  fill: #37789e;
}
.rangesliderchart .leftlabel {
  text-anchor: end;
  alignment-baseline: central;
  font-size: 16px;
  font-weight: 500;
  fill: #333333;
}
.rangesliderchart .topAxis {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  stroke: none;
}
.rangesliderchart .topAxis line {
  stroke: #999999;
}
.rangesliderchart .topAxis path {
  stroke: #999999;
}

.piepercentages {
  alignment-baseline: middle;
  text-anchor: middle;
  fill: white;
  font-size: small;
  font-weight: 600;
}

.pielabelleft {
  text-anchor: start;
  alignment-baseline: hanging;
}

.pielabelright {
  text-anchor: end;
  alignment-baseline: hanging;
}
.pielabels {
  alignment-baseline: hanging;
}

.pietitle {
  font-size: large;
  font-weight: 600;
  fill: 333333;
  alignment-baseline: hanging;
}

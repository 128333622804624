@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  li {
    list-style-type: disc;
  }
  .App a {
    color: #37789e;
  }
  .App a:hover {
    color: #02334f;
  }
  h1 {
    font-family: "Petrona", serif;
    @apply text-5xl;
  }
}
@layer components {
  .widthcontainer {
    @apply w-full max-w-6xl mx-auto pl-4 pr-4;
  }
  .narrowwidthcontainer {
    @apply max-w-4xl mx-auto pl-4 pr-4;
  }
  .characterfont {
    font-family: "Petrona", serif;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Petrona&display=swap");

/* === */

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* .xApp xh2 {
  font-family: "Manrope", sans-serif;
}
.xApp h3 {
  font-family: "Manrope", sans-serif;
}
.xApp h4 {
  font-family: "Manrope", sans-serif;
} */

/* .App .navbar {
  background-color: initial;
}

.App .white-semi {
  background-color: rgba(255, 255, 255, 0.5);
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

.testimonial {
  padding: 40px;
  position: static;
}

.testimonial > .speechbub {
  width: 600px;
  height: 100px;
  background: #eeeeee;
  position: static;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 3px solid #eeeeee;
  margin: 20px;
}
.testimonial > .speechbub:after {
  content: "";
  position: static;
  left: 10px;
  top: 100px;
  width: 0px;
  height: 0px;
  border-top: 13px solid #eeeeee;
  border-right: 13px solid transparent;
  border-left: 13px solid transparent;
  margin: 0px 10px 40px 40px;
}

.testimonial > .testimonialgivername {
  text-align: left;
  position: static;
  margin: 2px;
  color: #000;
}
.testimonial > .testimonialgivertitle {
  position: static;
  text-align: left;
  margin: 2px;
  font-size: smaller;
  color: #aaaaaa;
}

/* .newhomeheader .title.is-1 {
  font-family: "Manrope", sans-serif;
  font-weight: 800;
  font-size: 60px;
} */

/* ==================================================== */
/* OLD HOME =========================================== */

/* .oldhome {
  background-color: #faf9fa;
  font-family: "Montserrat-Medium";
}
.oldhome > .homepageheader {
  background-color: #2a344e;
}
.oldhome > .homepageheader > h1,
.oldhome > .homepageheader > h2 {
  color: white;
  font-family: "Montserrat-Medium";
}
.oldhome > .homepagedisciplines {
  background-color: #faf9fa;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Montserrat-Medium";
}
.oldhome > .homepagefooter {
  background-color: #2a344e;
  color: white;
  font-family: "Montserrat-Medium";
}
.oldhome img {
  width: 100px;
}

.oldhome > .homepageheader > .homepagelogo {
  width: 181px;
  height: 156px;
} */

/* ==================================================== */
/* FONTS ============================================== */

/* @font-face {
  font-family: "NibPro-Regular";
  src: local("NibPro-Regular"), url("/public/fonts/NibPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: local("Montserrat-Medium"), url("./fonts/Montserrat/static/Montserrat-Medium.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "OpenSans-SemiBold";
  src: local("OpenSans-SemiBold"), url("./fonts/Open_Sans/static/OpenSans/OpenSans-SemiBold.ttf") format("truetype");
}

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;400;600;800&family=Montserrat:wght@400;600;700;800&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Manrope:wght@200;400;600;800&display=swap"); */

.doublesidedbarchart .centrelabel {
  text-anchor: middle;
  alignment-baseline: central;
}
.doublesidedbarchart .headerlabel {
  /* font-size: 20px; */
  /* font-family: Arial, Helvetica, sans-serif; */
  fill: 333333;
  font-size: large;
  font-weight: 600;
}
.doublesidedbarchart .lhs {
  text-anchor: end;
}
.doublesidedbarchart .rhs {
  text-anchor: start;
}
.doublesidedbarchart .chartbar {
  fill: #37789e;
  rx: 6px;
  ry: 6px;
}

.barlabelright {
  fill: #999999;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  alignment-baseline: central;
  text-anchor: start;
}
.barlabelleft {
  fill: #999999;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  alignment-baseline: central;
  text-anchor: end;
}
